<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Media
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="media-form">
          <v-file-input
            label="Upload image*"
            v-model="fields.url"
            accept="image/png, image/jpeg"
            placeholder="Choose a Image"
            outlined
            @change="urlHasChanged()"
            :error="errors.hasOwnProperty('url')"
            :error-messages="errors['url']"
          ></v-file-input>
          <!--<v-text-field
            label="Type *"
            v-model="fields.type"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-text-field>-->
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
          <v-switch
            label="Main Image?"
            v-model="fields.main_image"
            inset
            :error="errors.hasOwnProperty('main_image')"
            :error-messages="errors['main_image']"
          ></v-switch>

          <v-text-field
            label="Order"
            v-model="fields.order"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('order')"
            :error-messages="errors['order']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="media-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      media: {},
      fields: {
        name: null,
        url: null,
        url_has_changed: false,
        main_image: false,
        type: "image",
        order: 100,
      },
      errors: {},
    };
  },

  methods: {
    open(media = null) {
      if (media !== null) {
        this.media = media;
        this.isEditing = true;
        this.fields.name = media.name;
        this.fields.url = media.url ? new File([media.url], media.url) : null;
        this.fields.order = media.order;
        this.fields.type = "image";
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      payload.propertyId = this.$route.params.propertyId;

      if (this.isEditing) {
        payload.mediaId = this.media.id;
      }

      this.$store
        .dispatch("melrose/propertiesStore/saveMedia", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.media = {};
      this.fields = {
        name: null,
        url: null,
        main_image: false,
        type: "image",
        order: 100,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },

    urlHasChanged() {
      this.fields.url_has_changed = true;
    },
  },
};
</script>
