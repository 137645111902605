<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Pdf
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="pdf-form">
          <v-text-field
            label="Matterport url"
            v-model="fields.matterport_url"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('matterport_url')"
            :error-messages="errors['matterport_url']"
          ></v-text-field>
          <v-text-field
            label="Video 1"
            v-model="fields.video_url_1"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('video_url_1')"
            :error-messages="errors['video_url_1']"
          ></v-text-field>
          <v-text-field
            label="Video 2"
            v-model="fields.video_url_2"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('video_url_2')"
            :error-messages="errors['video_url_2']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="pdf-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        matterport_url: null,
        video_url_1: null,
        video_url_2: null,
      },
      errors: {},
    };
  },

  methods: {
    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.fields.matterport_url = property.matterport_url;
        this.fields.video_url_1 = property.video_url_1;
        this.fields.video_url_2 = property.video_url_2;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        propertyId: this.$route.params.propertyId,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveVideo", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.property = {};
      this.fields = {
        matterport_url: null,
        video_url_1: null,
        video_url_2: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
