<template>
  <div>
    <v-container>
      <v-row align="start">
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Images</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.imageDialog.open()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Image</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row align="start">
                <v-col
                  cols="3"
                  v-for="image in property.images"
                  :key="image.id"
                >
                  <v-card>
                    <v-img
                      :src="image.asset_urls.url"
                      class="white--text align-end"
                      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                      height="200px"
                      contain
                    >
                      <v-overlay
                        v-if="
                          property.main_image &&
                          image.id === property.main_image.id
                        "
                        absolute
                        color="rgba(0,0,0,0)"
                        class="justify-end align-start flex-grow-1"
                      >
                        <v-chip label color="accent" class="mt-4 mr-4"
                          >Main Image</v-chip
                        >
                      </v-overlay>
                      <v-card-title v-text="image.name"></v-card-title>
                    </v-img>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="blue lighten-4 blue--text"
                            v-on="on"
                            class="mr-2"
                            @click="$refs.imageDialog.open(image)"
                          >
                            <v-icon x-small>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            depressed
                            color="red lighten-4 red--text"
                            v-on="on"
                            @click="$refs.deleteImageDialog.openDelete(image)"
                          >
                            <v-icon x-small>mdi-archive</v-icon>
                          </v-btn>
                        </template>
                        <span>Archive</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title class="grey lighten-4">
              <div class="flex-grow-1">Videos</div>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.videoDialog.open(property)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-simple-table>
                <tbody>
                  <tr>
                    <th>Matterport url</th>
                    <td>{{ property.matterport_url }}</td>
                  </tr>
                  <tr>
                    <th>Video 1</th>
                    <td>{{ property.video_url_1 }}</td>
                  </tr>
                  <tr>
                    <th>Video 2</th>
                    <td>{{ property.video_url_2 }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <image-dialog ref="imageDialog" />
    <delete-image-dialog ref="deleteImageDialog" />
    <video-dialog ref="videoDialog" />
  </div>
</template>

<script>
import ImageDialog from "./images/MediaDialog.vue";
import DeleteImageDialog from "./images/DeleteMediaDialog.vue";
import VideoDialog from "./images/VideoDialog.vue";

export default {
  components: {
    ImageDialog,
    DeleteImageDialog,
    VideoDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Properties",
          disabled: false,
          to: { name: "module-melrose-properties-all" },
          exact: true,
        },
      ],
      dialog: false,
      loading: false,
      isEditing: false,
      images: {},
      fields: {
        name: null,
        url: null,
        type: null,
        propery_id: null,
      },
      errors: {},
    };
  },

  computed: {
    property() {
      return this.$store.getters["melrose/propertiesStore/get"];
    },
  },

  methods: {
    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      this.$store
        .dispatch("melrose/propertiesStore/saveImages", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.property.address.short,
      disabled: true,
    });
  },
};
</script>
